import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
  sliderAdPanel: css({
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'transparent',
    overflow:'hidden',
    '& .slick-prev:before, .slick-next:before': {
      color: 'black',
      display:'none'
    },
    '& .slick-next': {
      right: '-2%'
    },
    '& .slick-prev': {
      left: '-2%'
    }
  }),
  sliderImageWrapper: {
    height: '100%'
  },
  sliderImageWrapper2: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    flexDirection: 'column',
    //margin: 'auto',
    //maxWidth: '500px'
  },
  // sliderImage: css({
  //   //width: 'auto !important',
  //   //height:'100% !important',
  //   //display: 'block !important',
  //   margin: 'auto',
  //   //maxHeight: '180px'
  // })
  sliderImage : mq({
    margin: 'auto',
    width: ['100%','unset']
  })
}