import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

export default {
  pageTiles: css(
    mq({
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      margin: "2rem -10px",
      overflow: "hidden",
      marginLeft: ["-10px", null, null, null, "-2rem", "-5.5rem"],
      marginRight: ["-10px", null, null, null, "-2rem", "-5.5rem"],
    })
  ),
  pageTile__tile: css(
    mq({
      flex: ["0 0 1000%", null, "0 0 100%"],
      maxWidth: ["100%", null, "100%"],
      padding: "10px",
      paddingTop: "3rem",
      zIndex: 1,

      "&:nth-of-type(even)": {
        marginLeft: "auto",
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
        }),
      },
      "&:nth-of-type(1)": {
        paddingTop: "10px",
      },
    })
  ),

  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__content__details": {
        backgroundColor: "#008fc5",
        "& .pageTile__bsyc__title": {
          color: "white",
        },
        "& .pageTile__bsyc__desc": {
          color: "white",
        },
        "& .pageTile__bsyc__dot": {
          borderTopColor: "white",
        },
      },
    },
  }),

  tile__flip__wrapper: css(
    mq({
      zIndex: "-1",
      display: "flex",
      flexDirection: "row",
      justifyContent: ["center", null, "space-between"],
      width: "100%",
      height: "100%",
      cursor: "pointer",
      position: "relative",
      flexWrap: "wrap",
      alignItems: "center",
    })
  ),

  tile__bg: css(
    mq({
      height: ["230px", null, null, "490px"],
      flex: "1 1 300px",
      overflow: "hidden",
      position: "relative",
      boxShadow: "3px 5px 10px rgba(0,0,0,0.3)",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      overflow: "hidden",
      position: "absolute",
      top: "0px",
      width: "100%",
      ".slick-next, .slick-prev": {
        display: "none !important",
      },
    })
  ),

  tile__bg2__inner: css(
    mq({
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: ["232px", null, null, "492px"],
    })
  ),

  tile__details: css(
    mq({
      overflow: "hidden",
      flexGrow: "1",
      textAlign: "center",
      width: "100%",
      bottom: "0",
      backgroundColor: "#00467f",
      color: "#fff",
      zIndex: 1,
      flex: ["1 1 300px", null, "1 1 400px"],
      maxWidth: ["300px", null, "400px"],

      height: ["unset", null, "75%"],
      transition: "all .2s ease-in-out",
      position: "relative",
      marginTop: ["-20px", null, "0px"],
      boxShadow: "3px 5px 10px rgba(0,0,0,0.3)",
      "&.even": mq({
        marginLeft: ["0px", null, "-50px"],
        marginRight: "unset",
      }),
      "&.odd": mq({
        marginRight: ["0px", null, "-50px"],
        marginLeft: "unset",
      }),
      "& .pageTile__bsyc__container": mq({
        position: ["static,", null, "absolute"],
        top: "50%",
        left: "50%",
        transform: ["none", null, "translate(-50%, -50%)"],
        width: ["100%", null, "76%"],
        padding: ["1rem", null, "0px"],
        "& .pageTile__bsyc__title": {
          fontSize: "1.2rem",
          textTransform: "uppercase",
          fontWeight: "700",
          lineHeight: "1",
          margin: "0",
          paddingBottom: ".5rem",
          // color: variables.redColor,
        },
        "& .pageTile__bsyc__dot": {
          width: "67%",
          marginTop: "0px",
          marginBottom: "0px",
          paddingBottom: "0.5rem",
          borderTopColor: "white",
        },
        "& .pageTile__bsyc__desc": {
          lineHeight: "1.4",
          fontSize: "0.9rem",
          minHeight: "70px",
          // color: variables.darkGrayColor,
        },
      }),
    })
  ),
};
