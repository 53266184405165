/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    },
    [],
    null,
    true
  );
  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }
  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header
        css={[style.top, scrolled && style.topScrolled]}
        className={`${
          scrolled ? "topHeader_style_scrolled" : "topHeader_style"
        } `}
        id='top'
      >
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            src={
              scrolled && style.logoScrolled
                ? "/assets/logos/logo-colour.png"
                : "/assets/logos/logo-white.png"
            }
            alt='logo'
          />
        </SiteLink>
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
