/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../cms/utils/cmsUtils";
import { useEffect } from "react";
//import style from './style';

export function TextWithJavScript(props) {
  const item = props.item;
  const cssClass = props.cssClass;
  const code = cmsUtils.payload(item, "Code") || "";

  const javaScriptCode = code
    .substring(code.indexOf("<script"), code.indexOf("</script>") + 9)
    .trim();
  const nonjavaScriptCode = code.replace(javaScriptCode, "").trim();
  // console.log('nonjavaScriptCode', nonjavaScriptCode);

  useEffect(() => {
    const javaScriptTopCode = javaScriptCode
      .substring(
        javaScriptCode.indexOf("<script"),
        javaScriptCode.indexOf(">") + 1
      )
      .trim();
    const javaScriptInsideCode = javaScriptCode
      .substring(
        javaScriptCode.indexOf(">") + 1,
        javaScriptCode.indexOf("</script>")
      )
      .trim();

    const script = document.createElement("script");
    script.type = `text/javascript`;

    if (javaScriptInsideCode !== "") {
      script.text = javaScriptInsideCode;
    }

    if (javaScriptTopCode.includes("src=")) {
      let _src =
        javaScriptTopCode.indexOf('src="') === -1
          ? ""
          : javaScriptTopCode
              .substring(javaScriptTopCode.indexOf('src="'))
              .trim();
      let src = _src.substring(_src.indexOf('src="') + 5, _src.indexOf('"', 5));
      script.src = src;
    }

    if (javaScriptTopCode.includes("async")) {
      script.async = true;
    }

    if (javaScriptTopCode.includes("defer")) {
      script.defer = true;
    }

    if (javaScriptTopCode.includes("onload=")) {
      let _functionName = javaScriptTopCode.substring(
        javaScriptTopCode.indexOf("onload=") + 8
      );
      let functionName = _functionName
        .substring(0, _functionName.indexOf('"') - 1)
        .replace("()", "");
      script.onload = function () {
        window[functionName]();
      };
    }

    document.body.appendChild(script);
  }, [javaScriptCode]);

  return (
    <div
      className={cssClass}
      id={item.anchorName}
      dangerouslySetInnerHTML={{ __html: nonjavaScriptCode }}
    ></div>
  );
}
